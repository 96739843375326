import React, { useState, useRef } from 'react'
import style from './TextToSign.module.css'
import left from '../../resource/leftarrow.png'
import keyboardIcon from '../../resource/keyboard.png'
import { Link } from 'react-router-dom';

function TextToSign() {



  const [showkeyBoard, setshowkeyBoard] = useState(false);

  function toogleBoard() {
    setshowkeyBoard(!showkeyBoard);
  }

  const inputRef = useRef();

  const [curInput, setcurInput] = useState('');
  function handleChange(e) {
    setcurInput(e.target.value.toUpperCase());

  }

  async function translateMe() {
    console.log(curInput + "BOOM")
    if (curInput == '') {
      // Tostify
      return;
    }
    const resp = await fetch("https://leethere.tech/api/gujratiToEnglish", {
      method: 'POST',
      headers: {
        authKey: localStorage.getItem('token'),
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        text: curInput
      })
    });
    const respText = await resp.text();
    setcurInput(respText);
  }
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.control}>
          <Link to={'/'}>
            <img src={left} className={style.leftIc} alt="" />
          </Link>

        </div>
        <h1 className={style.practice}>Gujrati to ISL</h1>
        <textarea className={style.questionPanel} placeholder='enter text here..' onChange={handleChange} value={curInput}>
        </textarea>
      </div>

      <img src={keyboardIcon} className={style.keyIcon} alt="" />
      <div className={style.nextBtn} onClick={translateMe}>
        Translate
      </div>
      <div className={style.kbd}>
      </div>
    </div >
  )
}

export default TextToSign
