import { useState, useEffect } from "react";
import logo from "../resource/logo.png";
import { Link } from "react-router-dom";

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        localStorage.setItem('token', "eyJhbGciOiJIUzI1NiJ9.dGVzdA.doEyl3_ZZ0X3zO6AEYTKL_Bb2rYq1mojPMjiN05v7YM")
    }, [])
    return (
        <div className="w-full h-screen overflow-y-hidden">
            <div className="bg-[#F68026] h-[760px] m-4 rounded-xl">
                <div className="items-center flex justify-center">
                    <img className="w-44 mt-5" src={logo} alt="" />
                </div>
                <div className="w-[330px] h-[550px] bg-white mx-auto rounded-xl space-y-12">
                    <div className="text-center text-3xl text-[#F68026] pt-10">Login</div>
                    <div>
                        <input
                            className=" text-2xl px-3 outline-none pb-3 pl-8 w-72"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Username"
                        />
                        <hr class="border-t-4 border-[#F68026] w-72 mx-auto"></hr>
                    </div>

                    <div>
                        <input
                            className=" text-2xl px-3 outline-none pb-3 pl-8 w-72"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="password"
                        />

                        <hr class="border-t-4 border-[#F68026] w-72 mx-auto"></hr>
                    </div>
                    <div className=" flex flex-col space-y-2 items-center justify-center">
                        <Link to="/"><button type="submit" className=" border p-3 px-10 text-2xl rounded-lg bg-[#F68026] text-white font-bold">Login</button></Link>
                        <p className="text-xl text-[#8F8F8F]">Don't have an account?<Link to='/signup'><button className="text-2xl text-[#F68026]">Sign Up</button></Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;