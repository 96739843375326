import './App.css'
import Keyboard from './component/keyboard/Keyboard';
import Practice from './component/practice/Practice';
import TestGujrati from './component/gujratiPractice/TestGujrati';
import TextToSign from './component/textToSign/TextToSign';
import SignToSpeech from './component/signToSpeech/SignToSpeech'
import Signup from './component/Signup';
import Login from './component/Login';
import Introduction from './component/Introduction';
import Home from './component/Home';
import { Routes, Route } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      {/* <Home></Home> */}
      {/* <div className="kbd">
        <Keyboard />
      </div> */}
      {/* <Practice></Practice> */}
      {/* <TextToSign></TextToSign> */}
      {/* <SignToSpeech></SignToSpeech> */}
      {/* <Signup /> */}
      {/* <Login /> */}
      {/* <Introduction /> */}
      {/* <Home /> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        {/* <Route path="/learnviaenglish" element={<LearnViaEnglish />} /> */}
        <Route path="/practice" element={<Practice />} />
        <Route path="/texttosign" element={<TextToSign></TextToSign>} />
        <Route path="/texttospeech" element={<TextToSign />} />

        <Route path='/signtospeech' element={<SignToSpeech></SignToSpeech>} />
        <Route path='/login' element={<Login></Login>} />
        <Route path='/signup' element={<Signup></Signup>} />
        <Route path='/testgujrati' element={<TestGujrati></TestGujrati>} />
        
      </Routes>
    </div>
  );
}

export default App;