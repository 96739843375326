import logo from '../resource/nitLogo.png';
import { Link } from "react-router-dom";
const Home = () => {
    return (
        <div className="flex flex-col">
            <div className=" border border-black my-8 w-96 mx-4 h-60 rounded-2xl bg-gradient-to-br from-[#F68026] to-gray-400
                text-4xl flex justify-center items-center text-center relative ">
                <img  src={logo} className='w-36 h-36 absolute left-3'/>
                <p className='absolute right-3 font-sans text-[#410c0c]'><span className='text-5xl'>G</span>ODS <span className='text-5xl'>T</span>EAM</p>
                <div className="border-2 rounded-full w-24 h-24 transform left-[-45px] absolute  bg-slate-200 opacity-15"></div>
                <div className="border-2 rounded-full w-24 h-24 absolute right-[-30px] bg-slate-200 opacity-15"></div>
                <div className="border-2 rounded-full w-24 h-24 absolute top-[-30px] left-20 bg-slate-200 opacity-15"></div>
                <div className="border-2 rounded-full w-12 h-12 absolute top-2 right-16 bg-slate-200 opacity-15"></div>
                {/* <p className="drop-shadow-2xl">Welcome to Gujrati Sign Language</p> */}
            </div>
            <div className="my-0 flex flex-col gap-4 p-4 mx-auto">
                <Link to="/introduction">
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  font p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl  text-[#410c0c]">Introduction</button>
                </Link>
                <Link to="/practice">
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">Learn Gujrati ISL</button>
                </Link>
                <Link to="/testgujrati">
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">Practice Gujrati ISL</button>
                </Link>
                {/* <Link to="/texttosign">
                    <button className="border-4 border-[#F68026] p-3 max-w-full w-96 mx-auto text-2xl tracking-wide rounded-xl text-[#F68026]">Text to Sign</button>
                </Link> */}
                <Link to='/texttospeech'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">Gujrati to ISL</button>
                </Link>
                <Link to='/signtospeech'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">ISL to Gujrati Speech</button>
                </Link>
                {/* <Link to='/login'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wide rounded-xl text-white">Login</button>
                </Link> */}
                {/* <Link to='signup'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  outline-none  p-3 max-w-full w-96 mx-auto text-2xl tracking-wide rounded-xl text-white">Sign Up</button>
                </Link> */}
                <Link to='signup'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  outline-none  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">Gujrati Speech to ISL</button>
                </Link>
                <Link to='intoduction'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  outline-none  p-3 max-w-full w-96 mx-auto text-2xl tracking-wider rounded-xl text-[#410c0c]">About Us</button>
                </Link>
            </div>
        </div>
    )
}

export default Home;
