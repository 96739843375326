import logo from '../resource/logo.png';
import arrow from '../resource/arrow.png';
import { Link } from 'react-router-dom';
const Introduction = () => {
    return (
        <div className='space-y-5 m-4'>
            <div>
                <img className='w-20 h-20' src={logo} alt="" />
            </div>
            <div className='text-[#F68026] text-3xl font-semibold '><span className='text-5xl font-bold'>I</span>ntroduction</div>
            <div>
                <p className='text-[#8F8F8F]'>The Government of Gujarat’s Education Department, emphasizing inclusivity, aims to
                    enhance learning for deaf and mute students through a comprehensive mobile application.
                </p>
            </div>
            <div className='text-[#F68026] text-4xl'><span className='text-5xl'>V</span>alues</div>
            <div>
                <p className='text-md text-[#8F8F8F]'><span className='text-xl font-bold text-black'>Accesible Learning: </span>Easily learn Gujarati through visual cues and sign language, making education accessible for all.</p>
            </div>
            <div>
                <p className='text-md text-[#8F8F8F]'><span className='text-xl font-bold text-black'>Interactive Exprience: </span>Engage with interactive lessons and quizzes designed to reinforce learning and retention.</p>
            </div>

            {/* <div className='flex bg-[#F68026] w-36 rounded-2xl ml-52'>
                <button className=' text-3xl p-2  px-5 text-white font-bold'>Start </button>
                <img className='w-8 h-full my-auto' src={arrow} alt='' />
            </div> */}
            <div className="my-7  flex flex-col gap-6 p-4 mx-auto" style={{ marginTop: "100px" }}>
                <Link to='/login'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  p-3 max-w-full w-96 mx-auto text-2xl tracking-wide rounded-xl text-white">Login</button>
                </Link>
                <Link to='/signup'>
                    <button className="bg-gradient-to-r from-[#F68026] to-gray-400  outline-none  p-3 max-w-full w-96 mx-auto text-2xl tracking-wide rounded-xl text-white">Sign Up</button>
                </Link>
            </div>
        </div>
    )
}

export default Introduction;