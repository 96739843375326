import React, { useState, useRef } from 'react'
import style from './TestGujrati.module.css'
import left from '../../resource/leftarrow.png'
import Keyboard from '../keyboard/Keyboard'
import { Link } from 'react-router-dom';
function TestGujrati() {
  const inputRef = useRef();

  const [subLang, setsubLang] = useState('');
  const [userPoint, setuserPoint] = useState(0);

  const [questionID, setquestionID] = useState('');

  const [wrongQuestion, setwrongQuestionQuestion] = useState(0);
  const [rightQuestion, setrightQuestionQuestion] = useState(0);



  const [curInput, setcurInput] = useState('');
  function handleChange(e) {
    setcurInput(e.target.value.toUpperCase());

  }

  const customOnClick = (curChar) => {
    inputRef.current.focus();
    if (curChar.target.innerText == "space") {
      setcurInput(curInput + " ");
    } else if (curChar.target.innerText == "") {
      if (curInput != "")
        setcurInput(curInput.slice(0, curInput.length - 1))
    } else {
      setcurInput(curInput + curChar.target.innerText);
    }
  }

  const grabQuestion = async () => {
    let mainUrl = 'https://leethere.tech/api/practice'
    if (questionID != '') {
      if (curInput == '') {
        // Tosify Alert
        return;
      }
      mainUrl += "?enteredWord="+curInput+"&solvedID="+questionID;
    }
    const resp = await fetch(mainUrl, {
      method: 'GET',
      headers: {
        authKey: localStorage.getItem('token')
      }
    });
    const respJson = await resp.json();
    setsubLang(respJson["gujratiText"]);
    setuserPoint(respJson["userpoint"]);
    setquestionID(respJson["QuestionID"])
    setcurInput('');
  }

  useState(() => {
    grabQuestion();
  }, [])
  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.control}>
          <Link to={'/'}>
            <img src={left} className={style.leftIc} alt="" />
          </Link>

          <div className={style.circle}>
            <p>{userPoint}</p>
          </div>
        </div>
        <h1 className={style.practice}>Practice</h1>
        <div className={style.questionPanel}>
          <div className={style.circle1}>
            <h1>{rightQuestion + wrongQuestion}</h1>
          </div>
          <div className={style.control1}>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <p>{rightQuestion}</p>
              <div style={{ height: "8px", width: "30px", backgroundColor: "green", marginLeft: "10px", borderRadius: "10px" }}></div>
            </div>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <div style={{ height: "8px", width: "30px", backgroundColor: "orange", marginRight: "10px", borderRadius: "10px" }}></div>
              <p>{wrongQuestion}</p>
            </div>
          </div>
          <h1 style={{ marginTop: "10px", color: "orange", fontWeight: "bold" }}>Type the Word</h1>
          <div className={style.qTitle}>
            {/* <p style={{ fontWeight: "bold", fontSize: "20px", display: "flex", justifyContent: "center" }}>{subLang}</p> */}
            <p style={{ fontWeight: "bold", fontSize: "32px", display: "flex", justifyContent: "center" }}>{subLang}</p>
          </div>
        </div>
      </div>
      <div className={style.lower}>
        <input type="text" inputMode='none' placeholder='type here..' ref={inputRef} value={curInput} onInput={handleChange} />
      </div>
      <div className={style.nextBtn} onClick={grabQuestion} >
        Next
      </div>
      <div className={style.kbd}>
        <Keyboard customOnClick={customOnClick} />
      </div>
    </div>

  )
}

export default TestGujrati