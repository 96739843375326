import React, { useState, useRef } from 'react'
import left from '../../resource/leftarrow.png'
import play from '../../resource/play.png'
import pause from '../../resource/pause.png'

import style from './SignToSpeech.module.css'
import Keyboard from '../keyboard/Keyboard';
import { Link } from 'react-router-dom'


function SignToSpeech() {
  const [isPlay, setisPlay] = useState(false);

  const [curzIndex, setcurzIndex] = useState(1000);
  const [showkeyBoard, setshowkeyBoard] = useState(false);

  function toogleBoard() {

    setshowkeyBoard(!showkeyBoard);
    if (!showkeyBoard) {
      setcurzIndex(1000)
    } else {
      setcurzIndex(-1000)
    }
  }


  const inputRef = useRef();

  const [curInput, setcurInput] = useState('');
  function handleChange(e) {
    setcurInput(e.target.value.toUpperCase());

  }

  const customOnClick = (curChar) => {
    inputRef.current.focus();
    if (curChar.target.innerText == "space") {
      setcurInput(curInput + " ");
    } else if (curChar.target.innerText == "") {
      if (curInput != "")
        setcurInput(curInput.slice(0, curInput.length - 1))
    } else {
      setcurInput(curInput + curChar.target.innerText);
    }
  }

  const AudioPlayer = new Audio();
  async function loadTheAudio() {

    if (curInput == '') {
      // Tostify
      return;
    }
    const resp = await fetch("https://leethere.tech/api/textToSpeech", {
      method: 'POST',
      headers: {
        authKey: localStorage.getItem('token'),
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        text: curInput
      })
    });

    const base64Mp3 = await resp.text();
    AudioPlayer.src = "data:audio/mp3;base64," + base64Mp3;
    AudioPlayer.play();
    setisPlay(true);
  }


  return (
    <div className={style.parent}>
      <div className={style.container}>
        <div className={style.control}>
          <Link to={'/'}>
            <img src={left} className={style.leftIc} alt="" />
          </Link>
        </div>
        <h1 className={style.practice}  >ISL to Gujrati Speech</h1>
        <textarea ref={inputRef} className={style.questionPanel} value={curInput} placeholder='enter text here..' inputMode='none' onClick={toogleBoard}>
        </textarea>
      </div>
      <img src="" className={style.keyIcon} alt="" />
      <div className={style.line}></div>
      <div className={style.playBtn} onClick={loadTheAudio}>
        <img src={isPlay ? pause : play} alt="" />
      </div>
      <div className={style.kbd} style={{
        zIndex: curzIndex,
      }}>
        {showkeyBoard && <Keyboard customOnClick={customOnClick} />}
      </div>

    </div>
  )
}

export default SignToSpeech
