import React from 'react'
import style from './Keyboard.module.css'
import backspace from '../../resource/backspace.png'
import enter from '../../resource/enter.png'
import emoji from '../../resource/happy.png'
function Keyboard({customOnClick}) {
  return (
    <div className={style.container}>
    <div className={style.signContainer}>
      <div className={style.row0}>
        <h1 onClick={customOnClick}>1</h1>
        <h1 onClick={customOnClick}>2</h1>
        <h1 onClick={customOnClick}>3</h1>
        <h1 onClick={customOnClick}>4</h1>
        <h1 onClick={customOnClick}>5</h1>
        <h1 onClick={customOnClick}>6</h1>
        <h1 onClick={customOnClick}>7</h1>
        <h1 onClick={customOnClick}>8</h1>
        <h1 onClick={customOnClick}>9</h1>
        <h1 onClick={customOnClick}>0</h1>
      </div>
      <div className={style.row1}>
        <h1 className={style.sign} onClick={customOnClick}>Q</h1>
        <h1 className={style.sign} onClick={customOnClick}>W</h1>
        <h1 className={style.sign} onClick={customOnClick}>E</h1>
        <h1 className={style.sign} onClick={customOnClick}>R</h1>
        <h1 className={style.sign} onClick={customOnClick}>T</h1>
        <h1 className={style.sign} onClick={customOnClick}>Y</h1>
        <h1 className={style.sign} onClick={customOnClick}>U</h1>
        <h1 className={style.sign} onClick={customOnClick}>I</h1>
        <h1 className={style.sign} onClick={customOnClick}>O</h1>
        <h1 className={style.sign} onClick={customOnClick}>P</h1>
      </div>
      <div className={style.row2}>
        <h1 className={style.sign} onClick={customOnClick}>A</h1>
        <h1 className={style.sign} onClick={customOnClick}>S</h1>
        <h1 className={style.sign} onClick={customOnClick}>D</h1>
        <h1 className={style.sign} onClick={customOnClick}>F</h1>
        <h1 className={style.sign} onClick={customOnClick}>G</h1>
        <h1 className={style.sign} onClick={customOnClick}>H</h1>
        <h1 className={style.sign} onClick={customOnClick}>J</h1>
        <h1 className={style.sign} onClick={customOnClick}>K</h1>
        <h1 className={style.sign} onClick={customOnClick}>L</h1>
      </div>
      <div className={style.row3}>
        <h1 className={style.sign} onClick={customOnClick}>
          <img src={emoji} style={{ height: "30px", width: "30px" }} alt="" />
        </h1>
        <h1 className={style.sign} onClick={customOnClick}>Z</h1>
        <h1 className={style.sign} onClick={customOnClick}>X</h1>
        <h1 className={style.sign} onClick={customOnClick}>C</h1>
        <h1 className={style.sign} onClick={customOnClick}>V</h1>
        <h1 className={style.sign} onClick={customOnClick}>B</h1>
        <h1 className={style.sign} onClick={customOnClick}>N</h1>
        <h1 className={style.sign} onClick={customOnClick}>M</h1>
        <h1 className={style.sign} onClick={customOnClick}>
          <img className={style.backspace} src={backspace} alt="" />
        </h1>
      </div>
    </div>
    <div className={style.extraOption}>
      <h1 className={style.sign} onClick={customOnClick} style={{ fontSize: "1.4em", display: "flex", justifyContent: "center", alignItems: "center" }}>
        123
      </h1>
      <h1 className={style.space} onClick={customOnClick}>space</h1>
      <h1 className={style.sign} onClick={customOnClick}>
        <img className={style.enter} src={enter} alt="" />
      </h1>
    </div>
  </div>
  
  )
}

export default Keyboard
